@import "../../vars.scss";
@import "../../mixins.scss";


.HeroHome {
    //border: $border-test;
    width: 100vw;
    height: 35vh;
    position: relative;
}

.HeroHome2{
    position: relative;
    width: 100%;
    height: 50vh;
    // display: flex;
    overflow: hidden;
    // justify-content: center;
    // align-items: center;
}

.HeroHome2 iframe{
    position: absolute;
    width: 100%;
    // height: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 250%;
   
}

.text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #AC974E;
  
}

.text h1{
    font-size: 80px;
    margin-bottom: 0px;
}

.text p{
    margin-top: -5px;
    font-size: 50px;
    color: white;
}

.HeroHome__textura {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}

.HeroHome__color {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
}

.HeroHome__logo {
    width: 40%;
    height: 100%;
    position: absolute;
    right: 5%;
    bottom: 0;
    z-index: 2;
    object-fit: contain;
}

.HeroHome__description {
    margin: 0;
    width: 50%;
    height: 100%;
    position: absolute;
    left: 2%;
    top: 25%;
    z-index: 2;
    font-family: $font-unna;
    font-weight: 700;
    color: #fff;
    font-size: 3em;
}


@media screen and (max-width: 700px) {
    .HeroHome__description {
        font-size: 2.5rem;
        top: 60%;
        left: 10%;
        width: auto;
    }

    .HeroHome {
        height: 40vh;
    }

    .HeroHome__logo {
        width: 60%;
        height: 50%;
        position: absolute;
        right: 10%;
        top: 0;
        z-index: 2;
    }

}

@media screen and(max-width: 400px) {
    .HeroHome__description {
        font-size: 1.8rem;
        top: 60%;
        left: 10%;
        width: auto;
    }
}
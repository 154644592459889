@import "../../vars.scss";
@import "../../mixins.scss";

.Socios2{
    // border: $border-test;
    overflow: hidden;
    overflow-y: auto;
    width: 80vw;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    margin-bottom: 30px;
    margin-top: 20px;
}


.Socios__item1{
    // border: $border-test;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: auto ;

    & .Socios__item-profile{
        // border: $border-test;
        grid-column: 1/2;
        @include basic-flexbox(center,center,row);
        & div{
            // border: $border-test;
            width: 100%;
            height: 90%;
            // margin-left: 10%;
            margin-top: 2%;
            position: relative;
            @include basic-flexbox(center,center,column);

            & *{
                margin: 0;
                padding: 0;
            }
            & img:nth-of-type(1){
              object-fit: contain;
              // width: 40%;
              // height: 100%;
            }
            
            
            & div{
              position: relative;
              & img{
                // border: $border-test;
                display: none;
                  position: absolute;
                  right: 0;
                  
              }
                display: block;
                // margin-left: 10%;
                margin-top: 30px;
                // border: $border-test;


                & .Socio__item-name2{
                    color: $color-grey-meiumLight;
                    font-family: $font-unna;
                    font-weight: bold;
                    margin-top: 4%;
                    font-size: 1.5rem;
                    text-align: center;
                }

                & .Socio__item-status2{
                    color: $color-grey-meiumLight;
                    font-family: $font-unna;
                    font-size: 1.5em;
                    margin-bottom: 10%;
                    text-align: center;
                }

                & .Socio__item-info2{
                    color: #666D70;
                    color: $color-grey-meiumLight;
                    font-family: $font-montserrat;
                    font-size: 1em;
                    line-height: 20px;
                }
            }

        }
    }

    & .Socios__item-details{
        // border: $border-test;
        margin-top: 30px;
        

        & div{
            // border: $border-test;
            width: 100%;
            // margin-left: 10%;
            // margin-top: 1vh;
            display: grid;
            grid-template-columns: 40% 60%;
            grid-template-rows: repeat(4,auto);

            & div{
                all: initial;
                // border: $border-test;
                font-family: $font-unna;
                font-weight: normal;
                color: $color-grey-meiumLight;
                
            }

            & div:nth-child(odd){
                // border: $border-test;
                @include basic-flexbox(flex-end,flex-start,row);
                padding-right: 15%;
                font-size: 1.2rem;
                border-right: 2px solid $color-red-ihma;
                position: relative;
                text-align: right;

                & span{
                    // border: $border-test;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    right: -4.5%;
                    background-color: $color-red-ihma;
                    
                }
            }

            & div:nth-child(even){
                padding: 0 0 2vh 10%;
                font-family: $font-montserrat;
                font-size: 1rem;
                line-height: 18px;
            }
        }
    }
}


@media screen and(max-width: 1000px){
  .Socios2{
    width: 75vw;
  }
  .Socios2 .Socios__item1{
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    & .Socios__item-profile{
      grid-column: 1/2;
      grid-row: 1/2;
    }
  }

  .Socios__item1 .Socios__item-details div div:nth-child(odd){
    padding-right: 30px;
    font-size: 1rem;
  }

  .Socios__item1 .Socios__item-details div div:nth-child(even){
    font-size: 1rem;
    line-height: 25px;
  }

  .Socios__item1 .Socios__item-profile div div{
    margin-top: 0;
  }
  .Socios__item1 .Socios__item-profile div div .Socio__item-status2{
    margin-bottom: 5px;
  }

  .Socios__item1 .Socios__item-profile div div .Socio__item-info2{
    text-align: center;
  }
}

@media screen and(max-width: 1000px){
  .Socios__item1 .Socios__item-profile div div .Socio__item-name2{
    font-size: 1.2rem;
  }
  .Socios__item1 .Socios__item-profile div div .Socio__item-status2{
    font-size: 1.2rem;
  }
  .Socios__item1 .Socios__item-profile div div .Socio__item-info2{
    line-height: 18px;
    font-size: 0.8rem;
  }

  .Socios__item1 .Socios__item-details div div:nth-child(even){
    line-height: 18px;
    font-size: 0.8rem;
  }

  .Socios__item1 .Socios__item-details div{
    grid-template-columns: 50% 50%
  }
}
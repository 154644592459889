@import '../../vars.scss';
@import '../../mixins.scss';

.Clientes {
  // border: $border-test;
  // @include basic-flexbox(flex-start,flex-start,row);
  display: grid;
  grid-template-columns: 55% 40%;
  width: 100vw;
  padding-left: 5%;
  overflow: hidden;
}

.Clientes__content {
  // border: $border-test;
  grid-column: 1/2;
  @include basic-flexbox(flex-start, flex-start, column);


  & .Clientes__content-titulo {
    // border: $border-test;
    border-bottom: 2px solid $color-red-ihma;
    width: 70%;
    font-size: 2.2rem;
    line-height: 45px;
    font-family: $font-unna;
    color: #7E7E7E;
    position: relative;

    & div {
      position: absolute;
      width: 25px;
      height: 25px;
      background-color: $color-red-ihma;
      bottom: 0;
      right: 0;
    }
  }

  & .Clientes__content-descripcion {
    // border: 1px solid blue;
    width: 90%;
    text-align: justify;
    line-height: 27px;
    color: #666D70;
    font-size: 1.3rem;
    font-family: $font-arsenal;
  }

  & .Clientes__content-list {
    // border: 1px solid blue;
    column-count: 2;
    margin-top: 3vh;
    margin-bottom: 5vh;
    width: 100%;
    // line-height: 2.5em;

    // text-align: center;
    & div {
      // border: 1px solid blue;
      @include basic-flexbox(flex-start, center, row);

      & div {
        width: 15px;
        height: 15px;
        background-color: $color-red-ihma;
      }

      & p {
        width: 100%;

        // line-height: 25px;
        color: #666D70;
        font-size: 1.3rem;
        font-family: $font-arsenal;
        margin-left: 5%;
        margin-right: 5%;
        // margin-top: 0;
        // margin-bottom: 0;
      }

    }

  }
}

.Clientes__decorate {
  // border: 1px solid blue;
  grid-column: 2/3;
  width: 100%;
  height: 100%;

  & img {
    width: 100%;
    height: 100%;

  }
}

@media screen and(max-width: 1250px) {
  .Clientes__content {
    & .Clientes__content-titulo {
      width: 90%;
    }
  }
}

@media screen and(max-width: 1000px) {
  .Clientes {
    padding: 0;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 50vh auto;

    & .Clientes__decorate {

      & img {
        display: none;
      }

      background-image: url('../../img/clientes-movil.png');
      background-repeat: no-repeat;
      background-size:cover;
      // border: 1px solid red;
      grid-row: 1/2;
      grid-column: 1/2;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    & .Clientes__content {
      width: 80%;
      padding-left: 10%;
      grid-row: 2/3;
      grid-column: 1/2;
    }
  }

}

@media screen and(max-width: 550px) {

  .Clientes {
    grid-template-rows: 40vh auto;

  }

  .Clientes__content {
    & .Clientes__content-titulo {
      font-size: 1.7rem;
    }

    & .Clientes__content-descripcion {
      font-size: 1.2rem;
    }

    & .Clientes__content-list div p {
      font-size: 1rem;
    }
  }
}

@media screen and(max-width: 450px) {
  .Clientes__content {
    & .Clientes__content-list {
      column-count: 1;
    }

    & .Clientes__content-titulo {
      width: 95%;
      font-size: 1.2rem;
      line-height: 40px;

      & div {
        right: -8%;
        bottom: -5%;
      }
    }

    & .Clientes__content-descripcion {
      font-size: 1rem;
    }

  }
}
@import "../../vars.scss";
@import "../../mixins.scss";

.Socios {
    // border: $border-test;
    overflow: hidden;
    width: 100vw;
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-rows: 100%;
    // margin-bottom: 10vh;
}

.Socios__item:nth-of-type(1) {
    // border: $border-test;

    & img {
        width: 100%;
        height: 100%;
    }
}

.ModalLegal__card {
    border: 2px solid #AC974E;
    width: 120px;
    padding: 10px 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 8px;
    font-weight: bold;
    font-family: $font-arsenal;
}

.Socios__item:nth-of-type(2) {
    // border: $border-test;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;

    & .Socios__item-top {
        // border: $border-test;

        & div {
            // border: $border-test;
            // width: 80%;
            // height: 90%;
            margin-left: 10%;
            margin-top: 2%;
            position: relative;
            @include basic-flexbox(flex-start, flex-start, row);

            & * {
                margin: 0;
                padding: 0;
            }

            & img:nth-of-type(1) {
                width: 227px;
                height: 100%;
            }

            & img:nth-of-type(2) {
                position: absolute;
                right: 24em;
            }

            & div {
                display: block;
                // margin-left: 10%;

                & .Socio__item-name {
                    color: $color-grey-meiumLight;
                    font-family: $font-unna;
                    font-weight: bold;
                    margin-top: 2%;
                    font-size: 27px;
                }

                & .Socio__item-status {
                    color: $color-grey-meiumLight;
                    font-family: $font-unna;
                    font-size: 23px;
                    margin-bottom: 8%;
                }

                & .Socio__item-info {
                    color: #666D70;
                    color: $color-grey-meiumLight;
                    font-family: $font-arsenal;
                    font-size: 18px;
                    line-height: 30px;
                }
            }

        }
    }

    & .Socios__item-bottom {
        // border: $border-test;
        padding-bottom: 3em;


        & div {
            // border: $border-test;
            width: 65%;
            margin-left: 10%;
            margin-top: 5vh;
            display: grid;
            grid-template-columns: 30% 70%;
            grid-template-rows: repeat(4, auto);

            & div {
                all: initial;
                //border: $border-test;
                font-family: $font-unna;
                font-weight: normal;
                color: $color-grey-meiumLight;

            }

            & div:nth-child(odd) {
                @include basic-flexbox(flex-end, flex-start, row);
                padding-right: 15%;
                font-size: 1.5em;
                border-right: 2px solid $color-red-ihma;
                position: relative;
                text-align: right;

                & span {
                    //border: $border-test;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    right: -4.5%;
                    background-color: $color-red-ihma;

                }
            }

            & div:nth-child(even) {
                padding: 0 0 5vh 10%;
                font-family: $font-arsenal;
                font-size: 18px;
                line-height: 30px;
            }
        }
    }
}




@media screen and(max-width: 1000px) {


    .Socios {
        grid-template-rows: 40vh auto;
        grid-template-columns: 100%;

        .Socios__item:nth-of-type(1) {
            background-image: url('../../img/socioDecorationLittle.png');
            background-repeat: no-repeat;
            background-size: cover;
            margin-bottom: 5vh;

            & img {
                display: none;
            }
        }
    }

    .Socios__item:nth-of-type(2) .Socios__item-top div {

        margin-left: 5%;
        width: 90%;

        & div {
            margin-top: 5%;
            margin-left: 0%;
        }

        & div .Socio__item-status {
            margin-bottom: 4%;
        }
    }

    .Socios__item:nth-of-type(2) .Socios__item-bottom div {
        width: 80%;
        margin-left: 3%;
    }

    .Socios__item:nth-of-type(2) .Socios__item-bottom div div:nth-child(even) {
        line-height: 25px;
    }
}

@media screen and(max-width: 850px) {

    .Socios {
        grid-template-rows: 35vh auto;
    }

    .Socios__item:nth-of-type(2) .Socios__item-top div div {

        & .Socio__item-name {
            // font-size: 2rem;
        }

        & .Socio__item-info {
            font-size: 0.8rem;
        }
    }

    .Socios__item:nth-of-type(2) .Socios__item-bottom div {
        width: 90%;
        margin-left: 3%;
    }

    .Socios__item:nth-of-type(2) .Socios__item-bottom div div:nth-child(even) {
        font-size: 1rem;
    }
}

@media screen and(max-width: 550px) {
    .Socios__item:nth-of-type(2) .Socios__item-top div {
        width: 95%;
        margin: 0;
    }

    .Socios__item:nth-of-type(2) .Socios__item-top div div {

        & .Socio__item-name {
            font-size: 1.5rem;
        }

        & .Socio__item-info {
            font-size: 0.8rem;
        }
    }

    .Socios__item:nth-of-type(2) .Socios__item-bottom div {
        width: 95%;
        margin-left: 0;
    }

    .Socios__item:nth-of-type(2) .Socios__item-bottom div div:nth-child(odd) {
        font-size: 1.1rem;
    }

    .Socios__item:nth-of-type(2) .Socios__item-bottom div div:nth-child(odd) span {
        right: -3vw;
    }
}

@media screen and(max-width: 550px) {
    .Socios__item:nth-of-type(2) .Socios__item-top div {
        width: 95%;
        margin: 0;
    }

    .Socios__item:nth-of-type(2) .Socios__item-top {
        & img:nth-of-type(1) {
            width: 157px !important;
            height: 100%;
            margin-left: 1em;
        }
    }

    .Socios__item:nth-of-type(2) .Socios__item-top {
        & img:nth-of-type(2) {
            display: none;
        }
    }

    .Socios__item:nth-of-type(2) .Socios__item-top div div {

        & .Socio__item-name {
            font-size: 1.3rem;
        }

        & .Socio__item-info {
            font-size: 0.7rem;
            line-height: 15px;
        }
    }

    .Socios__item:nth-of-type(2) .Socios__item-bottom div {
        width: 95%;
        margin-left: 0;
    }

    .Socios__item:nth-of-type(2) .Socios__item-bottom div div:nth-child(odd) {
        font-size: 0.8rem;
    }
}
@import '../../vars.scss';
@import '../../mixins.scss';


.Alianzas {
  // border: 1px solid black;
  width: 100vw;
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-rows: auto;

  & .Alianzas__img {
    grid-column: 1/2;
    grid-row: 1/2;

    & img {
      width: 100%;
      height: 100%;
    }
  }

  & .Alianzas__text {
    // border: 1px solid red;
    padding: 2% 5%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 10% 90%;

    & .Alianzas__text-titles {
      // border: 1px solid blue;
      grid-column: 1/2;
      grid-row: 1/2;

      & h1 {
        // border: 1px solid black;
        margin: 0;
        font-family: $font-unna;
        color: $color-grey-meiumLight;
        font-size: 2.3rem;
        line-height: 80px;
        position: relative;
        display: inline-block;

        & img {
          position: absolute;
          top: 0;
          right: -30px;
        }
      }

      & h4 {
        // border: 1px solid black;
        font-family: $font-arsenal;
        color: $color-grey-meiumLight;
        font-size: 1.5rem;
        margin: 0;
        margin-top: 20px;
      }
    }

    & .Alianzas__text-details {
      // border: 1px solid black;
      grid-column: 1/2;
      grid-row: 2/3;
      text-align: justify;

      & p {
        font-family: $font-arsenal;
        color: $color-grey-meiumLight;
        font-size: 22px;

      }
    }

    & .Alianzas__text-img {
      border: 1px solid green;
      grid-column: 1/2;
      grid-row: 3/4;
      text-align: center;

      & a {
        display: block;
        margin: 30px 0;
        font-weight: bold;
        font-family: $font-unna;
        font-size: 1.5rem;
        margin: 0;
        margin-bottom: 10px;
      }
    }
  }
}

@media screen and(max-width: 1350px) {
  .Alianzas .Alianzas__text .Alianzas__text-titles h1 {
    font-size: 2rem;
    // width: 80%;
  }

  .Alianzas .Alianzas__text .Alianzas__text-titles h4 {
    font-size: 1.2rem;
  }

  .Alianzas .Alianzas__text .Alianzas__text-details p {
    font-size: 22px;
  }

  .Alianzas .Alianzas__text .Alianzas__text-img a {
    margin-top: 0;
  }

  .Alianzas .Alianzas__text {
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
  }
}


@media screen and(max-width: 1350px) {
  .Alianzas {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
  }

  .Alianzas .Alianzas__img {
    grid-column: 1/2;
    grid-row: 1/2;
    height: 300px;
    background-image: url('../../img/alianzas_mobile.png');
    background-repeat: none;
    background-size: cover;

    & img {
      display: none;
    }
  }

  .Alianzas .Alianzas__text {
    grid-column: 1/2;
    grid-row: 2/3;
  }
}


@media screen and(max-width: 650px) {
  .Alianzas .Alianzas__text {
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
  }

  .Alianzas .Alianzas__text .Alianzas__text-titles h1 {
    & img {
      // right: 1em;
    }

    font-size: 1.5rem;
  }
}

.Socios__item-bottom {
  // border: $border-test;
  padding-bottom: 3em;
  padding-top: 1em;

  & div {
    // border: $border-test;
    width: 100%;
    // margin-left: 10%;
    // margin-top: 5vh;
    display: grid;
    grid-template-columns: 0 100%;
    grid-template-rows: repeat(4, auto);

    & div {
      all: initial;
      //border: $border-test;
      font-family: $font-unna;
      font-weight: normal;
      color: $color-grey-meiumLight;

    }

    & div:nth-child(odd) {
      @include basic-flexbox(flex-end, flex-start, row);
      padding-right: 15%;
      font-size: 1.5em;
      border-right: 2px solid $color-red-ihma;
      position: relative;
      text-align: right;

      & span {
        //border: $border-test;
        width: 20px;
        height: 20px;
        position: absolute;
        right: -10px;
        background-color: $color-red-ihma;

      }
    }

    & div:nth-child(even) {
      padding: 0 0 5vh 35px;
      font-family: $font-arsenal;
      font-size: 18px;
      line-height: 30px;
    }
  }
}
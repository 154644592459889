@import url('https://fonts.googleapis.com/css2?family=Unna:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Arsenal:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

//colores
$color-nav: #ffffff;
$color-gray: #f1f1f1;
$color-gray-little: #C4C4C4;
$color-grey-medium: #535558;
$color-grey-meiumLight: #77787B ;
// $color-red-ihma: #E11B22;
$color-red-ihma: #AC974E;

$color-black-intense: #27282A;
//elementos de test 
$border-test: 1px solid #AC974E;
//fuentes
$font-unna: 'Unna', serif;
$font-arsenal: 'Arsenal', sans-serif;
$font-montserrat: 'Montserrat', sans-serif;
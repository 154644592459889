@import '../../vars.scss';
@import '../../mixins.scss';

.EquipoLegal {
    // border: $border-test;
    @include basic-flexbox(center, flex-start, row);
    width: 100vw;


}

.AreasPractica__decoration {
    // border: $border-test;
    // width: 25vw;
    height: 100%;
    min-height: 100%;

    & .AreasPractica__decoration-img {
        width: 100%;
        height: 100%;
    }
}

.EquipoLegal__about {
    // border: $border-test;
    width: 80vw;
    margin-bottom: 2vh;

    & .EquipoLegal__about-title {
        font-family: "Unna", serif;
        font-weight: bold;
        color: #77787B;
        width: 16vw;
        line-height: 80px;
        position: relative;
        font-size: 37px;

        & img {
            position: absolute;
            right: 0px;
        }
    }



    & .Equipolegal__about-description {
        // border: 1px solid red;
        text-align: justify;
        font-family: $font-arsenal;
        font-size: 1.4rem;
        font-style: normal;
        line-height: 27px;
        color: #666D70;
        margin-left: 10vw;
        margin-right: 30vw;
    }

}

@media screen and(max-width: 1000px) {
    .EquipoLegal {
        flex-direction: column;
        align-items: center;
    }

    .EquipoLegal__left {
        width: 100vw;
        height: 55vh;
        background-image: url('../../img/areasDecorateLittle.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;

        & .EquipoLegal__left-img {
            display: none;
        }
    }


    .EquipoLegal__about {
        width: 95vw;

        & .EquipoLegal__about-title {
            width: 70%;
            font-size: 2.4rem;
            line-height: 40px;
            border-bottom: 2px solid $color-red-ihma;
            background-image: url('../../img/cuadrado.png');
            background-repeat: no-repeat;
            background-position-y: 100%;
            background-position-x: 100%;
        }

        & .Equipolegal__about-description {
            // border: 1px solid black;
            font-size: 1.3rem;
            font-style: normal;
            line-height: 27px;
            color: #666D70;
            margin-left: 10vw;
            margin-right: 30vw;
        }

    }

    .EquipoLegal__about-item .EquipoLegal__about-item-name {
        width: 70%;
    }

    .EquipoLegal__about-socios {
        // margin-left: 5vw;
    }
}



@media screen and(max-width: 1100px) {
    .team-mobile-banner {
        height: 192px;
        width: 100%;
        background-image: url('../../img/areasDecorateLittle.png');
        background-repeat: no-repeat;
        background-size: cover;

        & img {
            display: none;
        }
    }


    .EquipoLegal__left {
        width: 100vw;
        height: 35vh;

    }


    .EquipoLegal__about {
        width: 90vw;

        & .EquipoLegal__about-title {
            font-size: 1.7rem;
            margin-left: 0;
        }

        & .Equipolegal__about-description {
            font-size: 1.1rem;
            margin-left: 0;
            margin-right: 10vw;
        }

    }

    .EquipoLegal__about-item .EquipoLegal__about-item-name {
        width: 70%;
    }

    .EquipoLegal__about-socios {
        margin-left: 0;
        padding-left: 0;
    }
}


@media screen and(max-width: 500px) {

    .team-mobile-banner {
        height: 192px;
        width: 100%;
        background-image: url('../../img/areasDecorateLittle.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;

        & img {
            display: none;
        }
    }

    .EquipoLegal__about {

        & .EquipoLegal__about-title {
            font-size: 1.5rem;
        }

        & .Equipolegal__about-description {
            font-size: 1rem;
        }

    }

    .EquipoLegal__about-item .EquipoLegal__about-item-name p {
        width: 90%;
        line-height: 35px;
        font-size: 1.2rem;
    }


}
@import "../../vars.scss";
@import "../../mixins.scss";

.MainHome {
    // border: $border-test;
    // border: 1px solid blue;
    margin-top: 40px;
    width: 99vw;
    height: auto;
    display: grid;
    grid-template-columns: 35% 30% 35%;
    grid-template-rows: auto auto;
    grid-row-gap: 6vh;
    // margin-bottom: 24vh;
    // overflow: hidden;
}

.MainHome__item {
    //  border: $border-test;
}

.MainHome__item:nth-of-type(1) div {
    // border: $border-test;
    width: 85%;
    height: 90%;
    position: relative;
    background-color: $color-gray-little;

    & div {
        // border: 1px solid blue;
        width: 100%;
        height: 100%;
        top: 5%;
        left: 5%;
        position: absolute;
        background: linear-gradient(90.01deg, rgba(96, 96, 96, 0.56) 0.01%, #555555 51.04%, #373737 141.03%, #373737 171.04%);
        opacity: 0.77;


        & div {
            // border: 1px solid green;
            width: 7%;
            height: 9%;
            position: absolute;
            left: 80%;
            top: 0;
            background: none;
            opacity: 1;
            background-color: $color-red-ihma;
        }

        & p {
            margin: 0;
            position: absolute;
            top: 15%;
            left: 10%;
            padding: 0 8px;
            font-family: $font-unna;
            font-weight: 700;
            color: $color-nav;
            font-size: 1.5em;
        }
    }

}

.MainHome__item:nth-of-type(2) {
    & * {
        margin: 0;
        padding: 0;
    }

    grid-column: 2/4;
    grid-row: 1/2;
    padding: 2% 5% 10% 5%;


    & p {
        // border: $border-test;
        font-family: $font-arsenal;
        color: $color-grey-medium;
        font-size: 1.4em;
        text-align: justify;

        & i {
            color: $color-red-ihma;
            font-weight: 700;
      
      }
     
    
    }
}

// .HomeCuadroGris{
//     font-size: .2em;
// }

.MainHome__item:nth-of-type(3) {

    // border: $border-test;
    & * {
        margin: 0;
        padding: 0;
    }

    grid-column: 1/3;
    grid-row: 2/3;
    padding: 6% 20% 8% 10%;


    & div {
        // border: $border-test;
        width: 60%;
        height: auto;
        position: relative;
        margin-bottom: 3%;

        & img {
            position: absolute;
            right: 0;
            top: -12px;
        }

        & p {
            // border: $border-test;
            font-family: $font-arsenal;
            font-weight: bold;
            font-size: 2em;
            line-height: 35px;
            color: $color-grey-meiumLight;
            margin-top: 13px;
        }
    }

    &>p {
        // border: $border-test;
        text-align: justify;
        font-family: $font-arsenal;
        font-size: 1.4em;
        line-height: 28px;
        color: $color-grey-medium;
    }
}

.MainHome__item:nth-of-type(4) {

    // border: 1px solid blue;
    & img {
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: 1500px) {
    .MainHome__item:nth-of-type(1) {
        & div div p {
            left: 5%;
            padding: 0 8px;
        }
    }

    .MainHome__item:nth-of-type(2) {
        padding-bottom: 7%;

        & p {
            font-size: 1.2rem;
        }
    }

    .MainHome__item:nth-of-type(3) {
        & div p {
            font-size: 1.8rem;
        }

        & p {
            font-size: 1.2rem;
        }
    }
}

@media screen and (max-width: 1300px) {
    .MainHome__item:nth-of-type(1) {
        & div div p {
            font-size: 1rem;
            left: 5%;
            padding: 0 8px;
        }
    }

    .MainHome__item:nth-of-type(2) {
        padding-left: 0;

        & p {
            font-size: 1.1rem;
        }
    }

    .MainHome__item:nth-of-type(3) {
        & div p {
            font-size: 1.6rem;
        }

        & p {
            font-size: 1.1rem;
        }
    }
}

@media screen and(max-width: 1100px) {
    .MainHome__item:nth-of-type(3) {
        & div {
            width: 80%;
        }
    }
}

@media screen and(max-width: 850px) {


    .MainHome {
        grid-template-columns: 100%;
        grid-template-rows: 40vh repeat(3, auto);
        grid-row-gap: 5vh;

    }

    .MainHome__item:nth-of-type(1) {
        grid-column: 1/2;
        grid-row: 1/2;

        & div div p {
            font-size: 2.5rem;
            left: 5%;
            padding: 0 8px;
        }
    }

    .MainHome__item:nth-of-type(2) {
        grid-column: 1/2;
        grid-row: 2/3;
        padding: 0 5%;

    }

    .MainHome__item:nth-of-type(3) {
        grid-column: 1/2;
        grid-row: 3/4;
        padding: 0 5%;
    }

    .MainHome__item:nth-of-type(4) {
        grid-column: 1/2;
        grid-row: 4/5;

    }
}

@media screen and(max-width: 550px) {


    .MainHome {
        grid-template-rows: 35vh repeat(3, auto);

    }

    .MainHome__item:nth-of-type(1) {
        & div div p {
            font-size: 1.2rem;
            left: 5%;
            padding: 0 8px;
        }
    }

    .MainHome__item:nth-of-type(2) {

        & p {
            text-align: left;
        }
    }

    .MainHome__item:nth-of-type(3) {

        & p {
            text-align: left;
        }

        & div p {
            font-size: 1.3rem;
            line-height: 25px;
        }

        & div {
            margin-bottom: 25px;
        }
    }
}

@media screen and(max-width:350px) {

    .MainHome {
        grid-template-rows: 30vh repeat(3, auto);

    }

    .MainHome__item:nth-of-type(1) {
        & div div p {
            font-size: 1.8rem;
            left: 10%;
        }
    }

    .MainHome__item:nth-of-type(3) {
        & div {
            width: 95%;
        }
    }
}
@import "../../vars.scss";
@import "../../mixins.scss";

.Navbar {
    width: 100vw;
    height: 120px;
    background-color: $color-nav;
    @include basic-flexbox(space-between, center, row);

}

.Navbar__desplegable {
    // border: $border-test;
    display: none;
    background-color: rgb(241, 240, 240);
    position: fixed;
    z-index: 10;
    right: 5px;
    top: 15px;
    width: 40vw;
    padding: 10px 20px 20px 20px;
    border-radius: 15px;

    & .Navbar__desplegable-item {
        margin-bottom: 15px;
    }

    & .Navbar__desplegable-item:nth-of-type(1) {
        margin-bottom: 25px;
    }

    & .Navbar__desplegable-item:nth-child(n+2) {
        // border: $border-test;  


        & a {
            text-decoration: none;
            color: black;
            border-bottom: 1px solid gray;
            font-family: $font-arsenal;
            font-weight: bold;
            line-height: 40px;
            color: #535558;
            position: relative;
            @include basic-flexbox(space-between, center, row);
            flex-wrap: wrap;



        }


    }

    // & .Navbar__desplegable-item:nth-of-type(4) {
    //     & a {
    //         margin-top: 10px;
    //         border: none;
    //     }

    //     & img {
    //         display: none;
    //     }

    //     & ul {
    //         // display: none;
    //         list-style: none;
    //         width: 100%;
    //         // margin: 0;
    //         padding: 0;
    //         text-align: right;

    //         & a {
    //             border-bottom: 1px solid gray;

    //         }

    //         & img {
    //             display: block;
    //         }
    //     }

    // }


    & .Navbar__desplegable-item:nth-of-type(8) {
        // border: 1px solid green;  

        border: 0;
        // margin-top: 40px;
        background-color: rgb(241, 240, 240);
        // @include basic-flexbox(space-between, center, row);

        & .Navbar__desplegable-more:nth-of-type(4) {
            & div {
                margin-top: 5px;
                width: 50px;
                height: 50px;
                background-color: $color-gray-little;
                @include basic-flexbox(center, center, row);
                border-radius: 50%;
            }
        }
    }
}


.Navbar__oculto {
    position: fixed;
    width: 40px;
    height: 40px;
    right: 0;
    margin-right: 5%;
    display: none;
    border-radius: 50%;
    z-index: 10;
}

.Navbar__logo {
    // border: $border-test;
    margin-left: 3vw;
    width: 20%;
    height: 100%;
    cursor: pointer;

    & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.Navbar__nav {
    // border: $border-test;
    @include basic-flexbox(space-around, center, row);
    width: 95%;
}

.Navbar__nav ul {
    // border: $border-test;
    list-style: none;
    width: 100%;
    display: flex;
    justify-content: center;

    & li {
        // float: left;
        // margin-right: 2%;
        // position: relative;
        position: relative; /* Añadido para posicionar el submenú relativo al menú principal */
        padding: 15px;

        & ul {
            display: none;
        }

        &:nth-of-type(3):hover {
            & ul {
                width: auto;
                box-shadow: 4px 7px 30px 0px rgba(145, 145, 145, 1);
                cursor: pointer;
                display: flex;
                flex-direction: column;
                padding-left: 10px;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-right: 15px;
                position: absolute;
                background: white;
                z-index: 10;

                & li {
                    line-height: 40px;
                    border-bottom: 1px solid $color-gray-little;
                    padding-left: 5px;

                    & a {
                        color: $color-grey-medium;
                        font-family: $font-unna;
                        font-weight: 700;
                    }
                }
            }
        }

        & a {
            text-decoration: none;
            display: flex;
            height: 100%;
        }

        & a span {
            color: $color-grey-medium;
            font-family: $font-unna;
            font-weight: 700;
            // border-right: solid 3px #E11B22;
            // border-left: solid 3px #E11B22;
            border-left: solid 3px #AC974E;

            padding: 0 12px;
            font-size: 18px;
            display: flex;
            align-items: center;
            height: 100%;
            text-align: center;
        }

        & a i {
            color: $color-red-ihma;
            font-size: 25px;
            font-weight: bold;
        }

        &:hover {
            background-color: rgb(238, 238, 238);
        }
    }

    & li:nth-child(7) {
        & a span {
            // border-right: solid 3px #E11B22;
            border-right: solid 3px #AC974E;

        }
    }
}

//otra agregada
.Navbar__nav ul li ul {
    display: none;
    position: absolute;
    top: 100%; /* Asegura que el submenú esté justo debajo del menú principal */
    left: 0;
    padding: 0;
    margin: 0;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Asegúrate de que el submenú esté por encima de otros elementos */
}

.Navbar__nav ul li:hover ul {
    display: block;
}


//fin





.Navbar__nav .Navbar__nav-language {
    //border: $border-test;
    width: 10%;
    height: 95%;
    @include basic-flexbox(center, center, column);

    & * {
        margin: 0;
    }

    & div {
        // border: $border-test;
        width: 50px;
        height: 50px;
        margin-top: 8%;
        @include basic-flexbox(center, center, column);
        border-radius: 50%;
        background-color: $color-gray;
        font-weight: bold;
        cursor: pointer;

        &:hover {
            background-color: $color-gray-little;
        }
    }
}

@media screen and(max-width: 1560px) {

    .Navbar__nav {
        width: 80%;
    }

    .Navbar__nav ul {
        width: 100%;
        // height: 45px;
    }
}

@media screen and(max-width: 1280px) {

    .Navbar__nav {
        width: 80%;
    }

    .Navbar__nav ul {
        width: 90%;
        padding-left: 0;

        & li {
            margin-right: 1%;
        }
    }
}

@media screen and(max-width: 1120px) {

    // .Navbar__desplegable{
    //     display: block;
    // }
    .Navbar__nav {
        display: none;
    }

    .Navbar__oculto {
        display: block;
        @include basic-flexbox(center, center, row);

    }

    .Navbar__logo {
        height: 98%;
        width: auto;
    }
}

@media screen and(max-width: 750px) {

    .Navbar__logo {
        height: 98%;
        // width: auto;
    }

    .Navbar__desplegable {
        width: 50vw;
    }
}

@media screen and(max-width: 500px) {

    .Navbar__logo {
        height: 98%;
        // width: 45%;
    }

    .Navbar__desplegable {
        width: 60vw;
    }
}

@media screen and(max-width: 350px) {

    .Navbar__logo {
        height: 98%;
        width: 80%;
    }
}
@import '../../vars.scss';
@import '../../mixins.scss';

.Home__direction {
  // border: $border-test;
  width: 100vw;
  height: 55vh;
  margin-top: 10vh;
  position: relative;
  @include basic-flexbox(flex-start, flex-start, row);

  & .Home__angel {
    width: 50%;
    height: 100%;
    background-image: url('../../img/angelMexico.png');
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    background-position: center;
  }

  & .map1 {
    width: 50%;
    height: 100%;
    background-image: url('../../img/mapa2.png');
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    background-position: center;

  }

  & .map2 {
    width: 50%;
    height: 100%;
    background-image: url('../../img/mapa.png');
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    background-position: center;

  }

  & .Home__direction-container {
    all: initial;
    // border: $border-test;
    position: absolute;
    width: 25%;
    height: auto;
    bottom: 5%;
    right: 5vw;
    padding: 1%;
    background-color: white;
    z-index: 2;
    box-shadow: 0px 5px 5px 5px rgba(0, 0, 0, 0.3);
    border-radius: 8px;

    & * {
      all: initial;
    }

    & .Home__direction-triangle {
      // border: 1px solid blue;
      position: absolute;
      top: -5.1%;
      background-color: white;
      width: 25px;
      height: 25px;
      transform: rotate(45deg);


    }

    & .Home__direction-place {
      margin: 0;
      margin-left: 10%;
      line-height: 50px;
      font-family: $font-arsenal;
      font-weight: bold;
      font-size: 1.2rem;
      color: $color-red-ihma;
    }

    & .Home__direction-especific {
      // border: 1px solid blue;
      @include basic-flexbox(flex-start, center, row);
      width: 100%;
      margin-bottom: 5%;

      & div {
        margin-left: 5%;
        font-family: $font-arsenal;
        line-height: 20px;
        color: #666D70;
      }
    }

    & .Home__direction-cel {
      // border: 1px solid blue;
      @include basic-flexbox(flex-start, center, row);
      width: 100%;
      margin-bottom: 5%;


      & div {
        margin-left: 5%;
        font-family: $font-arsenal;
        line-height: 20px;
        color: #666D70;
      }
    }
  }
}

@media screen and(max-width: 1500px) {
  .Home__direction {
    height: 50vh;
  }

  .Home__direction div .Home__direction-place {
    line-height: 30px;
  }
}

@media screen and(max-width: 1100px) {
  .Home__direction {
    height: 45vh;
  }

  .Home__direction div .Home__direction-place {
    line-height: 30px;
    font-size: 1rem;
  }

}

@media screen and(max-width: 800px) {
  .Home__direction {
    & img:nth-of-type(1) {
      display: none;
    }

    & img:nth-of-type(2) {
      width: 100%;
    }

    & div {
      padding-top: 2%;

      & .Home__direction-place {
        font-size: 0.9rem;
        line-height: 40px;
      }

      & .Home__direction-especific div {
        font-size: 0.9rem;
        line-height: 15px;
      }

      & .Home__direction-cel div {
        font-size: 0.9rem;
        line-height: 15px;
      }

      & .Home__direction-img {
        display: block;
      }

      width: auto;
      right: 40%;
      bottom: 15%;

    }
  }
}

@media screen and(max-width: 500px) {


  .Home__direction {

    & .Home__angel {
      display: none;
    }

    & .map1 {
      width: 100%;
      height: 100%;
      background-image: url('../../img/mapa.png');
      background-repeat: no-repeat;
      background-size: cover;
      object-fit: cover;
      background-position: center;

    }

    & .map2 {
      width: 100%;
      height: 100%;
      background-image: url('../../img/mapa.png');
      background-repeat: no-repeat;
      background-size: cover;
      object-fit: cover;
      background-position: center;

    }

    & .Home__direction-container {
      all: initial;
      // border: $border-test;
      position: absolute;
      width: 90%;
      height: auto;
      bottom: 5%;
      right: 3vw;
      padding: 2%;
      background-color: white;
      z-index: 2;
      box-shadow: 0px 5px 5px 5px rgba(0, 0, 0, 0.3);
      border-radius: 8px;
    }

    & div .Home__direction-triangle {
      right: 50%;
    }

    & div {

      width: auto;
      right: 30%;
      bottom: 5%;

    }
  }
}
body {
  background-color: rgb(255, 255, 255);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// img {
//   object-fit: cover;
// }

h1 {
  // border: 1px solid black;
  margin: 0;
  font-size: 2.3rem;
  position: relative;
  display: inline-block;

  & img {
    width: 130px !important;
    object-fit: contain;
    position: absolute;
    top: 0;
    right: -56px;
  }
}
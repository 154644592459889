@import "../../vars.scss";
@import "../../mixins.scss";

.Footer {
    // border: $border-test;
    width: 100vw;
    height: auto;
    background-color: $color-black-intense;
    @include basic-flexbox(center, center, row);
    padding: 5vh 0;

}

.footer-container {
    // border: $border-test;
    width: 95%;
    height: 70%;
    display: grid;
    grid-template-columns: repeat(2, 20%) 40% 20%;
}

.Footer__item {

    // border: $border-test;
    & div {
        & a {
            text-decoration: none;
        }
    }
}

.Footer__item:nth-of-type(1) {
    @include basic-flexbox(center, flex-start, row);

    & div p {
        margin: 0;
        padding: 0;
        color: white;
        font-family: $font-arsenal;
        font-weight: bold;
        line-height: 32px;
        font-size: 0.9em;

        &:hover {
            border-bottom: 1px solid white;
            cursor: pointer;
        }
    }
}

.Footer__item:nth-of-type(2) {
    @include basic-flexbox(center, flex-end, row);

    & div {
        margin-bottom: 8%;
    }

    & div p {
        margin: 0;
        padding: 0;
        color: $color-gray-little;
        font-family: $font-arsenal;
        font-weight: normal;
        line-height: 27px;
        font-size: 1em;

        &:hover {
            border-bottom: 1px solid $color-gray-little;
            cursor: pointer;
        }
    }
}

.Footer__item:nth-of-type(3) {
    //border: $border-test;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: 20% 80%;
    border-left: solid 2px #fff;
    padding-left: 1em;

    & div {
        //border: $border-test;
    }

    & div:nth-of-type(1) {
        grid-column: 1/3;
        grid-row: 1/2;
        @include basic-flexbox(center, center, row);
        color: white;
        font-family: $font-arsenal;
        font-weight: bold;
        line-height: 32px;
        font-size: 0.9em;

    }

    & div:nth-child(n+2) {
        padding: 3% 5%;
        font-family: $font-arsenal;
        font-weight: normal;
        line-height: 22px;
        font-size: 0.9em;
        color: white;

        & i {
            color: $color-red-ihma;
        }
    }
}

.Footer__item:nth-of-type(4) {
    // border-left: 3px solid white;
    // border-right: 3px solid white;
    @include basic-flexbox(center, center, row);

    & div div {
        margin-bottom: 15%;
    }

    & div div:nth-of-type(1) {
        color: white;
        font-family: $font-arsenal;
        font-weight: bold;
        line-height: 20px;
        font-size: 0.9em;
    }

    & div div:nth-child(n+2) {
        & a {
            text-decoration: none;
            margin-left: 15px;
            color: $color-gray-little;
            font-family: $font-arsenal;
            font-weight: normal;
            font-size: 1em;

            &:hover {
                border-bottom: 1px solid $color-gray-little;
            }
        }
    }
}


@media screen and(max-width: 1050px) {
    .footer-container {
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: 80% 20%;
        height: 90%;
    }

    .Footer__item:nth-of-type(1) {
        display: none;
    }

    .Footer__item:nth-of-type(2) {
        grid-column: 1/3;
        grid-row: 2/3;
        padding: 3% 0;
        align-items: center;

        & div {
            margin: 0;
            margin-top: 25px;
        }
    }

    .Footer__item:nth-of-type(3) {
        grid-column: 1/2;
        grid-row: 1/2;
        grid-template-columns: 100%;
        grid-template-rows: auto auto;

        & div:nth-of-type(1) {
            display: none;
        }

        & div:nth-of-type(2) {
            padding-top: 0;
            padding-bottom: 0;
        }

        & div:nth-of-type(3) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .Footer__item:nth-of-type(4) {
        grid-column: 2/3;
        grid-row: 1/2;
        border-right: 0;
    }
}

@media screen and(max-width: 1050px) {
    .footer-container {

        grid-template-rows: 85% 15%;
        height: 95%;
    }
}

@media screen and(max-width: 500px) {
    .Footer__item:nth-of-type(3) {
        border-left: none;
        border-right: solid 2px #fff;
    }
}
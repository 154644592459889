@import '../../vars.scss';
@import '../../mixins.scss';

.mainESG {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  color: #333;
}

.containerESG {
  padding: 40px;
  margin: 0 auto;
}

h1, h2 {
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
  // margin-top: -20px;
  text-align: center;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul ul {
  margin-top: 10px;
  margin-left: 20px;
}

ul li {
  font-size: 1.2em;
  color: #555;
  margin-bottom: 20px;
  line-height: 1.5;
  text-align: justify;
}

ul li span.highlight {
  color: #c48b0f;
  font-weight: bold;
}

ul li span.highlight-bullet {
  color: #c48b0f;
  font-weight: bold;
  list-style-type: disc;
}

ul li span.highlight-link {
  color: #b31212;
  font-weight: bold;
  text-decoration: underline;
}

.team {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.team-member {
  display: flex;
  flex-basis: 48%;
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.team-member img {
  width: 30%;
  object-fit: cover;
}

.team-info {
  padding: 20px;
  width: 70%;
}

.team-info .name {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.team-info .title {
  font-size: 1.2em;
  color: #777;
  margin-bottom: 10px;
}

.team-info .bio {
  font-size: 1em;
  color: #555;
  text-align: justify;
}

.full-width-image {
  display: block;
  max-width: 80%;
  height: auto;
  margin: 0 auto;
}

.h2ESG{
  margin-top: -44px;
  margin-bottom: 50px;
}

.h3ESG{
  margin-top: 40px;
  margin-bottom: 50px;
}


.list-container {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.list-item {
  width: 100%; /* Cada elemento ocupa la mitad del contenedor */
  box-sizing: border-box;
  padding: 5px; /* Espacio entre columnas */
  margin-bottom: 3px;
  color: #C48B10;
}


.two-columns {
  display: flex;
  gap: 20px;
}

.column {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: justify;
}



.images-container {
  display: flex;
  justify-content: space-between;
}

.images-container img {
  
  width: 20%; /* Ajusta según sea necesario */
  height: auto;
}

.Reconocimientos {
  // border: 1px solid black;
  width: 100vw;
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-rows: auto;

  & .Reconocimientos__img {
    grid-column: 1/2;
    grid-row: 1/2;

    & img {
      width: 100%;
      height: 100%;
    }
  }

  & .Reconocimientos__text {
    // border: 1px solid red;
    padding: 2% 5%;
    display: block;

    & .Reconocimientos__text-titles {
      // border: 1px solid blue;
      grid-column: 1/2;
      grid-row: 1/2;

      & h1 {
        // border: 1px solid black;
        margin: 0;
        font-family: $font-unna;
        color: $color-grey-meiumLight;
        font-size: 2.3rem;
        line-height: 80px;
        width: 45%;
        position: relative;

        & img {
          position: absolute;
          top: 0;
          right: 3em;
        }
      }
    }

    & .Reconocimientos__text-details {
      margin: 40px 0;
      margin-top: 10px;

      & p {
        // border: 1px solid green;
        text-align: justify;
        font-family: $font-arsenal;
        color: $color-grey-meiumLight;
        font-size: 1.5rem;
      }
    }

    & .Reconocimientos__text-img {
      width: 100%;
      // height: 80px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;

      & img {
        // height: 100%;
        // width: 30%;
        // margin-right: 3%;
        // height: 80%;
        margin: 1em;
      }
    }

  }
}

@media screen and(max-width: 1020px) {
  .Reconocimientos .Reconocimientos__text .Reconocimientos__text-titles h1 {
    width: 70%;
  }
}

@media screen and(max-width: 650px) {
  .Reconocimientos {

    grid-template-columns: 100%;
    grid-template-rows: auto auto;

    & .Reconocimientos__img {
      height: 25vh;
    }
  }

  .Reconocimientos .Reconocimientos__text .Reconocimientos__text-titles h1 {
    width: 80%;
    font-size: 2rem;
  }

  .Reconocimientos .Reconocimientos__text .Reconocimientos__text-details p {
    font-size: 1.2rem;
  }
}

@media screen and(max-width: 450px) {
  .Reconocimientos .Reconocimientos__text .Reconocimientos__text-titles h1 {
    & img {
      right: 0;
    }
  }

  .Reconocimientos__text-img {
    flex-direction: column;

    & img {
      width: 60%;
      // object-fit: contain;
      margin-bottom: 4em;
    }
  }

}
@import '../../vars.scss';
@import '../../mixins.scss';


.AreasPractica {
  // border: $border-test;
  width: 100vw;
  height: auto;
  // margin-bottom: 50px;
  @include basic-flexbox(flex-start, flex-start, row);

  & .AreasPractica__decoration {
    // border: $border-test;
    // width: 25vw;
    height: 100%;
    min-height: 100%;

    & .AreasPractica__decoration-img {
      width: 100%;
      height: 100%;
    }
  }
}

.AreasPractica__contenido {
  // border: $border-test;
  width: 70vw;
  margin-left: 5vw;
  height: 100%;
  margin-bottom: 1.5em;
}

.AreasPractica__contenido-title {
  // border: $border-test;
  font-family: $font-unna;
  font-weight: bold;
  color: $color-grey-meiumLight;
  // padding-bottom: 10px;
  // width: 22vw;
  line-height: 80px;
  position: relative;
  font-size: 37px;

  & img {
    position: absolute;
    right: -30px;
  }
}

.AreasPractica__contenido-subtitle {
  font-family: $font-arsenal;
  font-weight: bold;
  color: $color-grey-meiumLight;
  font-size: 22px;
  margin-top: 0;
}

.AreasPractica__contenido-description {
  font-family: $font-arsenal;
  font-weight: normal;
  color: $color-grey-meiumLight;
  font-size: 18px;
}


.container1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px; /* Espaciado entre columnas */
}
.column1 {
  background-color: #f0f0f0; /* Color de fondo opcional */
  padding: 10px; /* Espaciado interno opcional */
}

.color1{
  color:#AA965F;
  font-size: $font-arsenal;
    // font-weight: bold;
    font-size: 22px;
}


.color2{
  // color:#AA965F;
  color: $color-grey-meiumLight;
  font-size: $font-arsenal;
    // font-weight: bold;
    font-size: 22px;
}


.h4t{
  color: $color-grey-meiumLight;
  font-size: $font-arsenal;
    // font-weight: bold;
    font-size: 22px;
    margin-top: -30px;
    color:#AA965F;
}


.Publi__contenido-list {
  // border: $border-test;
  // display: grid;
  // grid-template-columns: repeat(2, 50%);
  // grid-template-rows: repeat(auto, 10vh);

  & div {
    @include basic-flexbox(flex-start, center, row);
    font-family: $font-arsenal;

  }

  & div div {
    width: 10px;
    height: 10px;
    background-color: $color-red-ihma;
    margin-right: 2vw;
  }

  & div a {
    text-decoration: none;
  }

  & div p {
    font-size: $font-arsenal;
    font-weight: bold;
    color: $color-grey-meiumLight;
    font-size: 22px;
    margin: 6px 0;
  }
}

@media screen and(max-width: 1100px) {
  .AreasPractica {
    flex-direction: column;
    align-items: center;

    & .AreasPractica__decoration {
      height: 22vh;
      width: 100%;
      background-image: url('../../img/areas-movil.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: bottom;

      & .AreasPractica__decoration-img {
        display: none;
      }
    }

    & .AreasPractica__contenido-title {
      // width: 90%;
      font-size: 30px;
      margin-top: 15px;
    }

    & .AreasPractica__contenido-subtitle {
      // background-image: url('../../img/escuadra.png');
      background-repeat: no-repeat;
      background-position: right;
      background-position-y: 1px;
      width: 90%;
      font-size: 1.5rem;
      line-height: 25px;
      padding-right: 5%;
    }


  }

  .f-c {
    flex-direction: column-reverse;
  }

  .b-g {
    background-image: url('../../img/equipo-legal-m.png') !important;
  }

}

@media screen and(max-width: 700px) {

  .AreasPractica {
    & .AreasPractica__contenido {
      width: 90%;
    }

    & .AreasPractica__decoration {
      height: 22vh;

    }

    & .AreasPractica__contenido-subtitle {
      width: 95%;
      font-size: 1.2rem;

    }


    & .AreasPractica__contenido-description {
      font-size: 1.2rem;
      text-align: left;
    }

    & .AreasPractica__contenido-list {
      grid-template-columns: 100%;

      & div p {
        font-size: 1.4rem;
        margin: 0;
        line-height: 55px;
      }
    }

  }
}

@media screen and(max-width: 500px) {
  .AreasPractica {
    // flex-direction: column-reverse;

    & .AreasPractica__decoration {
      height: 30vh;

    }

    & .AreasPractica__contenido-list {
      & div p {
        font-size: 1.2rem;
        line-height: 50px;
      }
    }

  }
}

@media screen and(max-width: 320px) {
  .AreasPractica {

    & .AreasPractica__contenido-list {
      & div p {
        font-size: 1rem;
        line-height: 40px;
      }
    }

  }
}